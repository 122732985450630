import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import CategoryService from 'services/CategoryService';
import Utils from 'utils';

export const initialState = {
  loading: false,
  isCategoriesLoading: false,
  isAddingCategoryLoading: false,
  isDeletingCategoryLoading: false,
  isEditingCategoryLoading: false,
  categoryDetail: null,
  categories: [],
  filteredCategories: [],
  error: null,
};

export const getCategories = createAsyncThunk(
  'categories/list',
  async (data, { rejectWithValue }) => {
    try {
      const response = await CategoryService.getCategories();
      console.log('response categories : ', response);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

export const filterCategories = createAsyncThunk(
  'categories/filter',
  async ({ searchArray, value, fromApi, data = {} }, { rejectWithValue }) => {
    try {
      if (fromApi) {
        const response = await CategoryService.filterCategories(data);
        return response;
      } else {
        const response = Utils.wildCardSearchUsingName(searchArray, value);
        return response;
      }
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

export const addCategories = createAsyncThunk(
  'categories/add',
  async (data, { rejectWithValue }) => {
    try {
      const response = await CategoryService.addCategories(data);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

export const editCategories = createAsyncThunk(
  'categories/edit',
  async (data, { rejectWithValue }) => {
    try {
      console.log('data ,,, ,, ', data);
      const response = await CategoryService.editCategories(data.body, data.id);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

export const deleteCategory = createAsyncThunk(
  'categories/delete',
  async (id, { rejectWithValue }) => {
    try {
      const response = await CategoryService.deleteCategories(id);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

// Slice
const categorySlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    clearCategories(state) {
      state.categories = [];
    },
    clearError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCategories.pending, (state) => {
        state.isCategoriesLoading = true;
        state.error = null;
        state.categoryDetail = null;
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        state.isCategoriesLoading = false;
        state.categories = action.payload;
        state.categoryDetail = null;
      })
      .addCase(getCategories.rejected, (state, action) => {
        state.isCategoriesLoading = false;
        state.error = action.payload;
        state.categoryDetail = null;
      })
      .addCase(filterCategories.pending, (state) => {
        state.isCategoriesLoading = true;
        state.error = null;
        state.categoryDetail = null;
      })
      .addCase(filterCategories.fulfilled, (state, action) => {
        state.isCategoriesLoading = false;
        state.filteredCategories = action.payload;
        state.categoryDetail = null;
      })
      .addCase(filterCategories.rejected, (state, action) => {
        state.isCategoriesLoading = false;
        state.error = action.payload;
        state.filteredCategories = state.categories;
        state.categoryDetail = null;
      })
      .addCase(addCategories.pending, (state) => {
        state.isAddingCategoryLoading = true;
        state.error = null;
        state.categoryDetail = null;
      })
      .addCase(addCategories.fulfilled, (state, action) => {
        state.isAddingCategoryLoading = false;
        state.categoryDetail = action.payload;
      })
      .addCase(addCategories.rejected, (state, action) => {
        state.isAddingCategoryLoading = false;
        state.error = action.payload;
      })
      .addCase(editCategories.pending, (state) => {
        state.isEditingCategoryLoading = true;
        state.error = null;
        state.categoryDetail = null;
      })
      .addCase(editCategories.fulfilled, (state, action) => {
        state.isEditingCategoryLoading = false;
        state.categoryDetail = action.payload;
      })
      .addCase(editCategories.rejected, (state, action) => {
        state.isEditingCategoryLoading = false;
        state.error = action.payload;
      })
      .addCase(deleteCategory.pending, (state) => {
        state.isDeletingCategoryLoading = true;
        state.error = null;
      })
      .addCase(deleteCategory.fulfilled, (state, action) => {
        state.isDeletingCategoryLoading = false;

        const deletedCategoryId =
          action.payload &&
          action.payload.category &&
          action.payload.category._id;
        state.categories = state.categories.filter(
          (category) => category._id !== deletedCategoryId
        );
        state.filteredCategories =
          state.filteredCategories &&
          state.filteredCategories.filter(
            (category) => category._id !== deletedCategoryId
          );
      })
      .addCase(deleteCategory.rejected, (state, action) => {
        state.isDeletingCategoryLoading = false;
        state.error = action.payload;
      });
  },
});

// Export the actions to be used in components
export const { clearCategories, clearError } = categorySlice.actions;

// Export the reducer to be used in the store
export default categorySlice.reducer;
