import fetch from 'auth/FetchInterceptor';

const PaymentService = {};

PaymentService.getPayments = function (params) {
  return fetch({
    url: '/order/payment/filter',
    method: 'get',
    params,
  });
};

PaymentService.addPayment = function (data) {
  return fetch({
    url: '/order/payment',
    method: 'post',
    data: data,
  });
};

PaymentService.deletePayment = function (id) {
  return fetch({
    url: '/order/payment/' + id,
    method: 'delete',
  });
};

PaymentService.editPayment = function (body, id) {
  return fetch({
    url: '/order/payment/' + id,
    method: 'put',
    data: body,
  });
};

PaymentService.getRecentPayments = function () {
  return fetch({
    url: '/order/payment/recent-payments',
    method: 'get',
  });
};
export default PaymentService;
