import fetch from 'auth/FetchInterceptor';

const SubCategoryService = {};

SubCategoryService.getSubCategories = function () {
  return fetch({
    url: '/subCategory',
    method: 'get',
  });
};

SubCategoryService.filterSubCategories = function (params) {
  return fetch({
    url: '/subCategory/filter',
    method: 'get',
    params: params,
  });
};

SubCategoryService.getSubCategoriesByCategory = function (id) {
  return fetch({
    url: '/subCategory/byCategory/' + id,
    method: 'get',
  });
};

SubCategoryService.addSubCategories = function (data) {
  return fetch({
    url: '/subCategory',
    method: 'post',
    data: data,
  });
};

SubCategoryService.deleteSubCategories = function (id) {
  return fetch({
    url: '/subCategory/' + id,
    method: 'delete',
  });
};

SubCategoryService.editSubCategories = function (body, id) {
  return fetch({
    url: '/subCategory/' + id,
    method: 'put',
    data: body,
  });
};

export default SubCategoryService;
