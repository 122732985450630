import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import SizeService from 'services/SizeService';
import Utils from 'utils';

export const initialState = {
  loading: false,
  isSizesLoading: false,
  isAddingSizeLoading: false,
  isDeletingSizeLoading: false,
  isEditingSizeLoading: false,
  sizeDetail: null,
  sizes: [],
  filteredSizes: [],
  error: null,
};

export const getSizes = createAsyncThunk(
  'sizes/list',
  async (data, { rejectWithValue }) => {
    try {
      const response = await SizeService.getSizes();
      console.log('response sizes : ', response);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

export const filterSizes = createAsyncThunk(
  'sizes/filter',
  async ({ searchArray, value, fromApi, data = {} }, { rejectWithValue }) => {
    try {
      if (fromApi) {
        const response = await SizeService.filterSizes(data);
        return response;
      } else {
        const response = Utils.wildCardSearchUsingName(searchArray, value);
        return response;
      }
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

export const addSize = createAsyncThunk(
  'sizes/add',
  async (data, { rejectWithValue }) => {
    try {
      const response = await SizeService.addSizes(data);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

export const editSize = createAsyncThunk(
  'sizes/edit',
  async (data, { rejectWithValue }) => {
    try {
      console.log('data ,,, ,, ', data);
      const response = await SizeService.editSizes(data.body, data.id);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

export const deleteSize = createAsyncThunk(
  'sizes/delete',
  async (id, { rejectWithValue }) => {
    try {
      const response = await SizeService.deleteSizes(id);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

// Slice
const sizeSlice = createSlice({
  name: 'sizes',
  initialState,
  reducers: {
    clearSizes(state) {
      state.sizes = [];
    },
    clearError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSizes.pending, (state) => {
        state.isSizesLoading = true;
        state.error = null;
        state.sizeDetail = null;
      })
      .addCase(getSizes.fulfilled, (state, action) => {
        state.isSizesLoading = false;
        state.sizes = action.payload;
        state.sizeDetail = null;
      })
      .addCase(getSizes.rejected, (state, action) => {
        state.isSizesLoading = false;
        state.error = action.payload;
        state.sizeDetail = null;
      })
      .addCase(filterSizes.pending, (state) => {
        state.isSizesLoading = true;
        state.error = null;
        state.sizeDetail = null;
      })
      .addCase(filterSizes.fulfilled, (state, action) => {
        state.isSizesLoading = false;
        state.filteredSizes = action.payload;
        state.sizeDetail = null;
      })
      .addCase(filterSizes.rejected, (state, action) => {
        state.isSizesLoading = false;
        state.error = action.payload;
        state.filteredSizes = state.sizes;
        state.sizeDetail = null;
      })
      .addCase(addSize.pending, (state) => {
        state.isAddingSizeLoading = true;
        state.error = null;
        state.sizeDetail = null;
      })
      .addCase(addSize.fulfilled, (state, action) => {
        state.isAddingSizeLoading = false;
        state.sizeDetail = action.payload;
      })
      .addCase(addSize.rejected, (state, action) => {
        state.isAddingSizeLoading = false;
        state.error = action.payload;
      })
      .addCase(editSize.pending, (state) => {
        state.isEditingSizeLoading = true;
        state.error = null;
        state.sizeDetail = null;
      })
      .addCase(editSize.fulfilled, (state, action) => {
        state.isEditingSizeLoading = false;
        state.sizeDetail = action.payload;
      })
      .addCase(editSize.rejected, (state, action) => {
        state.isEditingSizeLoading = false;
        state.error = action.payload;
      })
      .addCase(deleteSize.pending, (state) => {
        state.isDeletingSizeLoading = true;
        state.error = null;
      })
      .addCase(deleteSize.fulfilled, (state, action) => {
        state.isDeletingSizeLoading = false;

        const deletedSizeId =
          action.payload && action.payload.size && action.payload.size._id;
        state.sizes = state.sizes.filter((size) => size._id !== deletedSizeId);
        state.filteredSizes =
          state.filteredSizes &&
          state.filteredSizes.filter((size) => size._id !== deletedSizeId);
      })
      .addCase(deleteSize.rejected, (state, action) => {
        state.isDeletingSizeLoading = false;
        state.error = action.payload;
      });
  },
});

// Export the actions to be used in components
export const { clearSizes, clearError } = sizeSlice.actions;

// Export the reducer to be used in the store
export default sizeSlice.reducer;
