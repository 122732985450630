import fetch from 'auth/FetchInterceptor'

const ConfigService = {}

ConfigService.getPaymentAccounts = function () {
  return fetch({
    url: '/config/payment-account',
    method: 'get',
  })
}

ConfigService.filterPaymentAccounts = function (data) {
  return fetch({
    url: '/config/payment-account/filter',
    method: 'post',
    data: data,
  })
}

ConfigService.addPaymentAccount = function (data) {
  return fetch({
    url: '/config/payment-account',
    method: 'post',
    data: data,
  })
}

ConfigService.updatePaymentAccount = function (body, id) {
  return fetch({
    url: '/config/payment-account/' + id,
    method: 'put',
    data: body,
  })
}

ConfigService.deletePaymentAccount = function (id) {
  return fetch({
    url: '/config/payment-account/' + id,
    method: 'delete',
  })
}

ConfigService.getDeliveryPrices = function () {
  return fetch({
    url: '/config/deliveryPrice',
    method: 'get',
  })
}

ConfigService.addOrUpdateDeliveryPrice = function (data) {
  return fetch({
    url: '/config/deliveryPrice',
    method: 'post',
    data: data,
  })
}

ConfigService.deleteDeliveryPrice = function (id) {
  return fetch({
    url: '/config/deliveryPrice/' + id,
    method: 'delete',
  })
}

ConfigService.getDefaultDeliveryDay = function () {
  return fetch({
    url: '/config/defaultDeliveryDays',
    method: 'get',
  })
}

ConfigService.addOrUpdateDefaultDeliveryDay = function (data) {
  return fetch({
    url: '/config/defaultDeliveryDays',
    method: 'post',
    data: data,
  })
}
export default ConfigService
