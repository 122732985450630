import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import PaymentService from 'services/PaymentService';
import isEmpty from 'utils/isEmpty';

export const initialState = {
  loading: false,
  payments: [],
  isAddingPaymentLoading: false,
  paymentDetail: null,
  isEditingPaymentLoading: false,
  isDeletingPaymentLoading: false,
  isPaymentsLoading: false,
  error: null,
  isRecentOrdersLoading: false,
  recentPayments: [],
  isRecentPaymentsLoading: false,
  totalSalesCount: 'N/A',
};
const defaultDataParam = {
  page: 1,
};

export const getPayments = createAsyncThunk(
  'payment/list',
  async (data = defaultDataParam, { rejectWithValue }) => {
    try {
      const response = await PaymentService.getPayments(data);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

export const addPayment = createAsyncThunk(
  'payment/add',
  async (data, { rejectWithValue }) => {
    try {
      const response = await PaymentService.addPayment(data);
      return response;
    } catch (err) {
      var errorText = 'Error';
      if (!isEmpty(err.message)) {
        errorText = err.message;
      } else if (!isEmpty(err.respose.message)) {
        errorText = err.respose.message;
      }
      return rejectWithValue(errorText);
    }
  }
);

export const editPayment = createAsyncThunk(
  'payment/edit',
  async (data, { rejectWithValue }) => {
    try {
      const response = await PaymentService.editPayment(data.body, data.id);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

export const deletePayment = createAsyncThunk(
  'payment/delete',
  async (id, { rejectWithValue }) => {
    try {
      const response = await PaymentService.deletePayment(id);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

export const getRecentPayments = createAsyncThunk(
  'payment/recent-payments',
  async (data, { rejectWithValue }) => {
    try {
      const response = await PaymentService.getRecentPayments();
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

// Slice
const paymentSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPayments.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.isAddingPaymentLoading = false;
        state.paymentDetail = null;
        state.isEditingPaymentLoading = false;
        state.isDeletingPaymentLoading = false;
      })
      .addCase(getPayments.fulfilled, (state, action) => {
        state.loading = false;
        state.payments = action.payload;
      })
      .addCase(getPayments.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addPayment.pending, (state) => {
        state.isAddingPaymentLoading = true;
        state.error = null;
        state.paymentDetail = null;
      })
      .addCase(addPayment.fulfilled, (state, action) => {
        state.isAddingPaymentLoading = false;
        state.paymentDetail = action.payload;
      })
      .addCase(addPayment.rejected, (state, action) => {
        state.isAddingPaymentLoading = false;
        state.error = action.payload;
      })
      .addCase(editPayment.pending, (state) => {
        state.isEditingPaymentLoading = true;
        state.error = null;
        state.paymentDetail = null;
      })
      .addCase(editPayment.fulfilled, (state, action) => {
        state.isEditingPaymentLoading = false;
        state.paymentDetail = action.payload;
      })
      .addCase(editPayment.rejected, (state, action) => {
        state.isEditingPaymentLoading = false;
        state.error = action.payload;
      })
      .addCase(deletePayment.pending, (state) => {
        state.isDeletingPaymentLoading = true;
        state.error = null;
      })
      .addCase(deletePayment.fulfilled, (state, action) => {
        state.isDeletingPaymentLoading = false;
        const deletedPaymentId =
          action.payload &&
          action.payload.payment &&
          action.payload.payment._id;
        state.payments.results = state.payments.results.filter(
          (payment) => payment._id !== deletedPaymentId
        );
      })
      .addCase(deletePayment.rejected, (state, action) => {
        state.isDeletingPaymentLoading = false;
        state.error = action.payload;
      })
      .addCase(getRecentPayments.pending, (state) => {
        state.isRecentPaymentsLoading = true;
      })
      .addCase(getRecentPayments.fulfilled, (state, action) => {
        state.recentPayments = action.payload.recentPayments;
        state.totalSalesCount = action.payload.totalSalesCount;
        state.isRecentPaymentsLoading = false;
      })
      .addCase(getRecentPayments.rejected, (state, action) => {
        state.recentPayments = [];
        state.totalSalesCount = 'N/A';
        state.isRecentPaymentsLoading = false;
      });
  },
});

// Export the actions to be used in components
export const { clearError } = paymentSlice.actions;

// Export the reducer to be used in the store
export default paymentSlice.reducer;
