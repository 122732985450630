import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import BrandService from 'services/BrandService';
import Utils from 'utils';

export const initialState = {
  loading: false,
  isBrandsLoading: false,
  isAddingBrandLoading: false,
  isDeletingBrandLoading: false,
  isEditingBrandLoading: false,
  brandDetail: null,
  brands: [],
  filteredBrands: [],
  error: null,
};

export const getBrands = createAsyncThunk(
  'brands/list',
  async (data, { rejectWithValue }) => {
    try {
      const response = await BrandService.getBrands();
      console.log('response brands : ', response);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

export const filterBrands = createAsyncThunk(
  'brands/filter',
  async ({ searchArray, value, fromApi, data = {} }, { rejectWithValue }) => {
    try {
      if (fromApi) {
        const response = await BrandService.filterBrands(data);
        return response;
      } else {
        const response = Utils.wildCardSearchUsingName(searchArray, value);
        return response;
      }
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

export const addBrand = createAsyncThunk(
  'brands/add',
  async (data, { rejectWithValue }) => {
    try {
      const response = await BrandService.addBrands(data);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

export const editBrand = createAsyncThunk(
  'brands/edit',
  async (data, { rejectWithValue }) => {
    try {
      console.log('data ,,, ,, ', data);
      const response = await BrandService.editBrands(data.body, data.id);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

export const deleteBrand = createAsyncThunk(
  'brands/delete',
  async (id, { rejectWithValue }) => {
    try {
      const response = await BrandService.deleteBrands(id);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

// Slice
const brandSlice = createSlice({
  name: 'brands',
  initialState,
  reducers: {
    clearBrands(state) {
      state.brands = [];
    },
    clearError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBrands.pending, (state) => {
        state.isBrandsLoading = true;
        state.error = null;
        state.brandDetail = null;
      })
      .addCase(getBrands.fulfilled, (state, action) => {
        state.isBrandsLoading = false;
        state.brands = action.payload;
        state.brandDetail = null;
      })
      .addCase(getBrands.rejected, (state, action) => {
        state.isBrandsLoading = false;
        state.error = action.payload;
        state.brandDetail = null;
      })
      .addCase(filterBrands.pending, (state) => {
        state.isBrandsLoading = true;
        state.error = null;
        state.brandDetail = null;
      })
      .addCase(filterBrands.fulfilled, (state, action) => {
        state.isBrandsLoading = false;
        state.filteredBrands = action.payload;
        state.brandDetail = null;
      })
      .addCase(filterBrands.rejected, (state, action) => {
        state.isBrandsLoading = false;
        state.error = action.payload;
        state.filteredBrands = state.brands;
        state.brandDetail = null;
      })
      .addCase(addBrand.pending, (state) => {
        state.isAddingBrandLoading = true;
        state.error = null;
        state.brandDetail = null;
      })
      .addCase(addBrand.fulfilled, (state, action) => {
        state.isAddingBrandLoading = false;
        state.brandDetail = action.payload;
      })
      .addCase(addBrand.rejected, (state, action) => {
        state.isAddingBrandLoading = false;
        state.error = action.payload;
      })
      .addCase(editBrand.pending, (state) => {
        state.isEditingBrandLoading = true;
        state.error = null;
        state.brandDetail = null;
      })
      .addCase(editBrand.fulfilled, (state, action) => {
        state.isEditingBrandLoading = false;
        state.brandDetail = action.payload;
      })
      .addCase(editBrand.rejected, (state, action) => {
        state.isEditingBrandLoading = false;
        state.error = action.payload;
      })
      .addCase(deleteBrand.pending, (state) => {
        state.isDeletingBrandLoading = true;
        state.error = null;
      })
      .addCase(deleteBrand.fulfilled, (state, action) => {
        state.isDeletingBrandLoading = false;

        const deletedBrandId =
          action.payload && action.payload.brand && action.payload.brand._id;
        state.brands = state.brands.filter(
          (brand) => brand._id !== deletedBrandId
        );
        state.filteredBrands =
          state.filteredBrands &&
          state.filteredBrands.filter((brand) => brand._id !== deletedBrandId);
      })
      .addCase(deleteBrand.rejected, (state, action) => {
        state.isDeletingBrandLoading = false;
        state.error = action.payload;
      });
  },
});

// Export the actions to be used in components
export const { clearBrands, clearError } = brandSlice.actions;

// Export the reducer to be used in the store
export default brandSlice.reducer;
