import fetch from 'auth/FetchInterceptor';

const UserService = {};

UserService.getUsers = function (data = { role: 'user' }) {
  return fetch({
    url: '/user',
    method: 'get',
    params: data,
  });
};

UserService.getManagerProfile = function (id) {
  return fetch({
    url: '/user/manager/profile/' + id,
    method: 'get',
  });
};

UserService.addManagerProfile = function (data) {
  return fetch({
    url: '/user/manager/profile',
    method: 'post',
    data: data,
  });
};

UserService.deleteManagerProfile = function (id) {
  return fetch({
    url: '/user/manager/profile/' + id,
    method: 'delete',
  });
};

UserService.updateManagerProfile = function (id, data) {
  return fetch({
    url: '/user/manager/profile/' + id,
    method: 'put',
    data: data,
  });
};

UserService.getDeliveryPersonProfile = function (id) {
  return fetch({
    url: '/user/deliveryPerson/profile/' + id,
    method: 'get',
  });
};

UserService.addDeliveryPersonProfile = function (data) {
  return fetch({
    url: '/user/deliveryPerson/profile',
    method: 'post',
    data: data,
  });
};

UserService.deleteDeliveryPersonProfile = function (id) {
  return fetch({
    url: '/user/deliveryPerson/profile/' + id,
    method: 'delete',
  });
};

UserService.updateDeliveryPersonProfile = function (id, data) {
  return fetch({
    url: '/user/deliveryPerson/profile/' + id,
    method: 'put',
    data: data,
  });
};

UserService.getRecentlyJoinedUsers = function () {
  return fetch({
    url: '/user/recently-joined-users',
    method: 'get',
  });
};

UserService.filterUsers = function (params) {
  return fetch({
    url: '/user/filter',
    method: 'get',
    params: params,
  });
};

UserService.deleteUser = function (id) {
  return fetch({
    url: '/user/' + id,
    method: 'delete',
  });
};

UserService.blockUser = function (body, id) {
  return fetch({
    url: '/user/block/' + id,
    method: 'put',
    data: body,
  });
};

export default UserService;
