import { combineReducers } from 'redux'
import theme from './slices/themeSlice'
import auth from './slices/authSlice'
import product from './slices/productSlice';
import order from './slices/orderSlice';
import category from './slices/categorySlice';
import subCategory from './slices/subCategorySlice';
import subCategoryType from './slices/subCategoryTypeSlice';
import brand from './slices/brandSlice';
import user from './slices/userSlice';
import size from './slices/sizeSlice';
import color from './slices/colorSlice';
import payment from './slices/paymentSlice';
import banner from './slices/bannerSlice';
import notification from './slices/notificationSlice';
import config from './slices/configSlice'

const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    theme,
    auth,
    product,
    order,
    payment,
    category,
    subCategory,
    subCategoryType,
    brand,
    user,
    color,
    size,
    banner,
    notification,
    config,
    ...asyncReducers,
  })
  return combinedReducer(state, action)
}
  
export default rootReducer
