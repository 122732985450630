import axios from 'axios';
import { API_BASE_URL } from 'configs/AppConfig';
import { signOutSuccess } from 'store/slices/authSlice';
import store from '../store';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import { notification } from 'antd';

const unauthorizedCode = [401, 403];

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000
})

// Config
const TOKEN_PAYLOAD_KEY = 'authorization'

// API Request interceptor
service.interceptors.request.use(
  (config) => {
    const jwtToken = localStorage.getItem(AUTH_TOKEN) || null;
    if (jwtToken) {
      config.headers[TOKEN_PAYLOAD_KEY] = 'Bearer ' + jwtToken;
    }

    return config;
  },
  (error) => {
    // Do something with request error here
    notification.error({
      message: 'Error',
    });
    Promise.reject(error);
  }
);

// API respone interceptor
service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {

    let notificationParam = {
      message: '',
    };

    // Remove token and redirect
    if (unauthorizedCode.includes(error.response.status)) {
      notificationParam.message = 'Authentication Fail';
      notificationParam.description = 'Please login again';
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        notificationParam.description =
          error.response && error.response.data && error.response.data.message;
      }
      localStorage.removeItem(AUTH_TOKEN);
      store.dispatch(signOutSuccess());
    }
    if (error.response.status === 409) {
      notificationParam.message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        'Already Exist';
    } else if (error.response.status === 404) {
      notificationParam.message = (error.response &&
        error.response.data &&
        error.response.data.message) ||'Not Found';
    } else if (error.response.status === 500) {
      notificationParam.message = (error.response &&
        error.response.data &&
        error.response.data.message) ||'Internal Server Error';
    } else if (error.response.status === 508) {
      notificationParam.message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        'Time Out';
    } else {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        notificationParam.description =
          error.response && error.response.data && error.response.data.message;
      }
    }
    
    notification.error(notificationParam);

    return Promise.reject(error);
  }
);

export default service