import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import ColorService from 'services/ColorService';
import Utils from 'utils';

export const initialState = {
  loading: false,
  isColorsLoading: false,
  isAddingColorLoading: false,
  isDeletingColorLoading: false,
  isEditingColorLoading: false,
  colorDetail: null,
  colors: [],
  filteredColors: [],
  error: null,
};

export const getColors = createAsyncThunk(
  'colors/list',
  async (data, { rejectWithValue }) => {
    try {
      const response = await ColorService.getColors();
      console.log('response colors : ', response);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

export const filterColors = createAsyncThunk(
  'colors/filter',
  async ({ searchArray, value, fromApi, data = {} }, { rejectWithValue }) => {
    try {
      if (fromApi) {
        const response = await ColorService.filterColors(data);
        return response;
      } else {
        const response = Utils.wildCardSearchUsingName(searchArray, value);
        return response;
      }
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

export const addColor = createAsyncThunk(
  'colors/add',
  async (data, { rejectWithValue }) => {
    try {
      const response = await ColorService.addColors(data);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

export const editColor = createAsyncThunk(
  'colors/edit',
  async (data, { rejectWithValue }) => {
    try {
      console.log('data ,,, ,, ', data);
      const response = await ColorService.editColors(data.body, data.id);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

export const deleteColor = createAsyncThunk(
  'colors/delete',
  async (id, { rejectWithValue }) => {
    try {
      const response = await ColorService.deleteColors(id);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

// Slice
const colorSlice = createSlice({
  name: 'colors',
  initialState,
  reducers: {
    clearColors(state) {
      state.colors = [];
    },
    clearError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getColors.pending, (state) => {
        state.isColorsLoading = true;
        state.error = null;
        state.colorDetail = null;
      })
      .addCase(getColors.fulfilled, (state, action) => {
        state.isColorsLoading = false;
        state.colors = action.payload;
        state.colorDetail = null;
      })
      .addCase(getColors.rejected, (state, action) => {
        state.isColorsLoading = false;
        state.error = action.payload;
        state.colorDetail = null;
      })
      .addCase(filterColors.pending, (state) => {
        state.isColorsLoading = true;
        state.error = null;
        state.colorDetail = null;
      })
      .addCase(filterColors.fulfilled, (state, action) => {
        state.isColorsLoading = false;
        state.filteredColors = action.payload;
        state.colorDetail = null;
      })
      .addCase(filterColors.rejected, (state, action) => {
        state.isColorsLoading = false;
        state.error = action.payload;
        state.filteredColors = state.colors;
        state.colorDetail = null;
      })
      .addCase(addColor.pending, (state) => {
        state.isAddingColorLoading = true;
        state.error = null;
        state.colorDetail = null;
      })
      .addCase(addColor.fulfilled, (state, action) => {
        state.isAddingColorLoading = false;
        state.colorDetail = action.payload;
      })
      .addCase(addColor.rejected, (state, action) => {
        state.isAddingColorLoading = false;
        state.error = action.payload;
      })
      .addCase(editColor.pending, (state) => {
        state.isEditingColorLoading = true;
        state.error = null;
        state.colorDetail = null;
      })
      .addCase(editColor.fulfilled, (state, action) => {
        state.isEditingColorLoading = false;
        state.colorDetail = action.payload;
      })
      .addCase(editColor.rejected, (state, action) => {
        state.isEditingColorLoading = false;
        state.error = action.payload;
      })
      .addCase(deleteColor.pending, (state) => {
        state.isDeletingColorLoading = true;
        state.error = null;
      })
      .addCase(deleteColor.fulfilled, (state, action) => {
        state.isDeletingColorLoading = false;

        const deletedColorId =
          action.payload && action.payload.color && action.payload.color._id;
        state.colors = state.colors.filter(
          (color) => color._id !== deletedColorId
        );
        state.filteredColors =
          state.filteredColors &&
          state.filteredColors.filter((color) => color._id !== deletedColorId);
      })
      .addCase(deleteColor.rejected, (state, action) => {
        state.isDeletingColorLoading = false;
        state.error = action.payload;
      });
  },
});

// Export the actions to be used in components
export const { clearColors, clearError } = colorSlice.actions;

// Export the reducer to be used in the store
export default colorSlice.reducer;
