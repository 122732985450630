import fetch from 'auth/FetchInterceptor';

const SizeService = {};

SizeService.getSizes = function () {
  return fetch({
    url: '/size',
    method: 'get',
  });
};

SizeService.filterSizes = function (params) {
  return fetch({
    url: '/size/filter',
    method: 'get',
    params: params,
  });
};

SizeService.addSizes = function (data) {
  return fetch({
    url: '/size',
    method: 'post',
    data: data,
  });
};

SizeService.deleteSizes = function (id) {
  return fetch({
    url: '/size/' + id,
    method: 'delete',
  });
};

SizeService.editSizes = function (body, id) {
  return fetch({
    url: '/size/' + id,
    method: 'put',
    data: body,
  });
};

export default SizeService;
