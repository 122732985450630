import fetch from 'auth/FetchInterceptor';

const CategoryService = {};

CategoryService.getCategories = function () {
  return fetch({
    url: '/category',
    method: 'get',
  });
};

CategoryService.filterCategories = function (params) {
  return fetch({
    url: '/category/filter',
    method: 'get',
    params: params,
  });
};

CategoryService.addCategories = function (data) {
  return fetch({
    url: '/category/add',
    method: 'post',
    data: data,
  });
};

CategoryService.deleteCategories = function (id) {
  return fetch({
    url: '/category/' + id,
    method: 'delete',
  });
};

CategoryService.editCategories = function (body, id) {
  return fetch({
    url: '/category/' + id,
    method: 'put',
    data: body,
  });
};

export default CategoryService;
