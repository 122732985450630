import fetch from 'auth/FetchInterceptor';

const BrandService = {};

BrandService.getBrands = function () {
  return fetch({
    url: '/brand',
    method: 'get',
  });
};

BrandService.filterBrands = function (params) {
  return fetch({
    url: '/brand/filter',
    method: 'get',
    params: params,
  });
};

BrandService.addBrands = function (data) {
  return fetch({
    url: '/brand',
    method: 'post',
    data: data,
  });
};

BrandService.deleteBrands = function (id) {
  return fetch({
    url: '/brand/' + id,
    method: 'delete',
  });
};

BrandService.editBrands = function (body, id) {
  return fetch({
    url: '/brand/' + id,
    method: 'put',
    data: body,
  });
};

export default BrandService;
