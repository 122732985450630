import fetch from 'auth/FetchInterceptor';

const SubCategoryTypeService = {};

SubCategoryTypeService.getSubCategoryTypes = function () {
  return fetch({
    url: '/subCategoryType',
    method: 'get',
  });
};

SubCategoryTypeService.getSubCategoryTypesBySubCategory = function (id) {
  return fetch({
    url: '/subCategoryType/bySubCategory/' + id,
    method: 'get',
  });
};

SubCategoryTypeService.filterSubCategoryTypes = function (params) {
  return fetch({
    url: '/subCategoryType/filter',
    method: 'get',
    params: params,
  });
};

SubCategoryTypeService.addSubCategoryTypes = function (data) {
  return fetch({
    url: '/subCategoryType',
    method: 'post',
    data: data,
  });
};

SubCategoryTypeService.deleteSubCategoryTypes = function (id) {
  return fetch({
    url: '/subCategoryType/' + id,
    method: 'delete',
  });
};

SubCategoryTypeService.editSubCategoryTypes = function (body, id) {
  return fetch({
    url: '/subCategoryType/' + id,
    method: 'put',
    data: body,
  });
};

export default SubCategoryTypeService;
