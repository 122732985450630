import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { AUTH_TOKEN } from 'constants/AuthConstant'
import ConfigService from 'services/ConfigService'
import Utils from 'utils'

export const initialState = {
  loading: false,
  deliveryPrices: [],
  isDeliveryPricesLoading: false,
  isAddDeliveryPriceLoading: false,
  isDeleteDeliveryPriceLoading: false,
  deliveryPriceDetail: null,
  addedDeliveryPriceDetail: null,
  deletedDeliveryPriceDetail: null,
  error: null,
  isAddOrUpdateDefaultDeliveryDayLoading: false,
  defaultDeliveryDay: null,
  addedDefaultDeliveryDayDetail: null,

  paymentAccounts: [],
  isPaymentAccountsLoading: false,
  isAddingPaymentAccountLoading: false,
  isUpdatePaymentAccountLoading: false,
  isDeletingPaymentAccountLoading: false,
  filteredPaymentAccounts: [],
  addedPaymentAccountDetail: null,
  deletedPaymentAccountDetail: null,
}

export const getDeliveryPrices = createAsyncThunk(
  'config/deliveryPrices',
  async (data, { rejectWithValue }) => {
    try {
      const response = await ConfigService.getDeliveryPrices()
      return response
    } catch (err) {
      return rejectWithValue(err.message || 'Error')
    }
  }
)

export const addDeliveryPrice = createAsyncThunk(
  'config/deliveryPrices/add',
  async (data, { rejectWithValue }) => {
    try {
      const response = await ConfigService.addOrUpdateDeliveryPrice(data)
      return response
    } catch (err) {
      return rejectWithValue(err.message || 'Error')
    }
  }
)

export const deleteDeliveryPrice = createAsyncThunk(
  'config/deliveryPrices/delete',
  async (id, { rejectWithValue }) => {
    try {
      const response = await ConfigService.deleteDeliveryPrice(id)
      return response
    } catch (err) {
      return rejectWithValue(err.message || 'Error')
    }
  }
)

export const getDefaultDeliveryDay = createAsyncThunk(
  'config/defaultDeliveryDay',
  async (data, { rejectWithValue }) => {
    try {
      const response = await ConfigService.getDefaultDeliveryDay()
      return response
    } catch (err) {
      return rejectWithValue(err.message || 'Error')
    }
  }
)

export const addOrUpdateDefaultDeliveryDay = createAsyncThunk(
  'config/defaultDeliveryDay/add',
  async (data, { rejectWithValue }) => {
    try {
      const response = await ConfigService.addOrUpdateDefaultDeliveryDay(data)
      return response
    } catch (err) {
      return rejectWithValue(err.message || 'Error')
    }
  }
)

export const getPaymentAccounts = createAsyncThunk(
  'config/paymentAccounts',
  async (data, { rejectWithValue }) => {
    try {
      const response = await ConfigService.getPaymentAccounts()
      return response
    } catch (err) {
      return rejectWithValue(err.message || 'Error')
    }
  }
)

export const filterPaymentAccounts = createAsyncThunk(
  'config/paymentAccounts/filter',
  async (data, { rejectWithValue }) => {
    try {
      const response = await ConfigService.filterPaymentAccounts(data)
      return response
    } catch (err) {
      return rejectWithValue(err.message || 'Error')
    }
  }
) 

export const addPaymentAccount = createAsyncThunk(
  'config/paymentAccounts/add',
  async (data, { rejectWithValue }) => {
    try {
      const response = await ConfigService.addPaymentAccount(data)
      return response
    } catch (err) {
      return rejectWithValue(err.message || 'Error')
    }
  }
)

export const updatePaymentAccount = createAsyncThunk(
  'config/paymentAccounts/update',
  async (data, { rejectWithValue }) => {
    try {
      const response = await ConfigService.updatePaymentAccount(data.body, data.id)
      console.log('response : ', response);
      return response
    } catch (err) {
      return rejectWithValue(err.message || 'Error')
    }
  }
)

export const deletePaymentAccount = createAsyncThunk(
  'config/paymentAccounts/delete',
  async (id, { rejectWithValue }) => {
    try {
      const response = await ConfigService.deletePaymentAccount(id)
      return response
    } catch (err) {
      return rejectWithValue(err.message || 'Error')
    }
  }
)

// Slice
const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    clearDerliveryPrices(state) {
      state.deliveryPrices = []
    },
    clearAddedDefaultDeliveryDay(state) {
      state.isAddOrUpdateDefaultDeliveryDayLoading = false
      state.addedDefaultDeliveryDayDetail = null
    },
    clearError(state) {
      state.error = null
    },
    clearPaymentAccounts(state) {
      state.paymentAccounts = []
    },
    clearAddedPaymentAccount(state) {
      state.isAddPaymentAccountLoading = false
      state.addedPaymentAccountDetail = null
    },
    clearUpdatedPaymentAccount(state) {
      state.isUpdatePaymentAccountLoading = false
      state.updatedPaymentAccountDetail = null
    },
    clearDeletedPaymentAccount(state) {
      state.isDeletingPaymentAccountLoading = false
      state.deletedPaymentAccountDetail = null
    },
    clearError(state) {
      state.error = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDeliveryPrices.pending, (state) => {
        state.isDeliveryPricesLoading = true
        state.error = null
      })
      .addCase(getDeliveryPrices.fulfilled, (state, action) => {
        state.isDeliveryPricesLoading = false
        state.deliveryPrices = action.payload
      })
      .addCase(getDeliveryPrices.rejected, (state, action) => {
        state.isDeliveryPricesLoading = false
        state.error = action.payload
        state.addedDeliveryPriceDetail = null
      })
      .addCase(addDeliveryPrice.pending, (state) => {
        state.isAddDeliveryPriceLoading = true
        state.error = null
        state.addedDeliveryPriceDetail = null
      })
      .addCase(addDeliveryPrice.fulfilled, (state, action) => {
        state.isAddDeliveryPriceLoading = false
        state.addedDeliveryPriceDetail = action.payload
        state.deliveryPrices = [action.payload, ...state.deliveryPrices]
      })
      .addCase(addDeliveryPrice.rejected, (state, action) => {
        state.isAddDeliveryPriceLoading = false
        state.error = action.payload
      })
      .addCase(deleteDeliveryPrice.pending, (state) => {
        state.isDeleteDeliveryPriceLoading = true
        state.error = null
        state.deletedDeliveryPriceDetail = null
      })
      .addCase(deleteDeliveryPrice.fulfilled, (state, action) => {
        const deletedDeliveryPriceId = action.payload?._id

        if (deletedDeliveryPriceId) {
          state.deliveryPrices = state.deliveryPrices.filter(
            (deliveryPrice) => deliveryPrice._id !== deletedDeliveryPriceId
          )
        }
        state.deletedDeliveryPriceDetail = action.payload
        state.isDeleteDeliveryPriceLoading = false
      })
      .addCase(deleteDeliveryPrice.rejected, (state, action) => {
        state.isDeleteDeliveryPriceLoading = false
        state.error = action.payload
        state.deletedDeliveryPriceDetail = null
      })
      .addCase(getDefaultDeliveryDay.pending, (state) => {
        state.isDefaultDeliveryDayLoading = true
        state.error = null
      })
      .addCase(getDefaultDeliveryDay.fulfilled, (state, action) => {
        state.isDefaultDeliveryDayLoading = false
        state.defaultDeliveryDay = action.payload
      })
      .addCase(getDefaultDeliveryDay.rejected, (state, action) => {
        state.isDefaultDeliveryDayLoading = false
        state.error = action.payload
      })
      .addCase(addOrUpdateDefaultDeliveryDay.pending, (state) => {
        state.isAddOrUpdateDefaultDeliveryDayLoading = true
        state.error = null
        state.addedDefaultDeliveryDayDetail = null
      })
      .addCase(addOrUpdateDefaultDeliveryDay.fulfilled, (state, action) => {
        state.isAddOrUpdateDefaultDeliveryDayLoading = false
        state.defaultDeliveryDay = action.payload
        state.addedDefaultDeliveryDayDetail = action.payload
      })
      .addCase(addOrUpdateDefaultDeliveryDay.rejected, (state, action) => {
        state.isAddOrUpdateDefaultDeliveryDayLoading = false
        state.error = action.payload
        state.addedDefaultDeliveryDayDetail = null
      })

      .addCase(getPaymentAccounts.pending, (state) => {
        state.isPaymentAccountsLoading = true
        state.error = null
      })
      .addCase(getPaymentAccounts.fulfilled, (state, action) => {
        state.isPaymentAccountsLoading = false
        state.paymentAccounts = action.payload
      })
      .addCase(getPaymentAccounts.rejected, (state, action) => {
        state.isPaymentAccountsLoading = false
        state.error = action.payload
      })
      .addCase(filterPaymentAccounts.pending, (state) => {
        state.isPaymentAccountsLoading = true
        state.error = null
      })
      .addCase(filterPaymentAccounts.fulfilled, (state, action) => {
        state.isPaymentAccountsLoading = false
        state.paymentAccounts = action.payload
      })  
      .addCase(filterPaymentAccounts.rejected, (state, action) => {
        state.isPaymentAccountsLoading = false
        state.error = action.payload
      })
      .addCase(addPaymentAccount.pending, (state) => {
        state.isAddingPaymentAccountLoading = true
        state.error = null
        state.addedPaymentAccountDetail = null
      })
      .addCase(addPaymentAccount.fulfilled, (state, action) => {
        state.isAddingPaymentAccountLoading = false
        state.addedPaymentAccountDetail = action.payload
        state.paymentAccounts = [action.payload, ...state.paymentAccounts]
      })
      .addCase(addPaymentAccount.rejected, (state, action) => {
        state.isAddingPaymentAccountLoading = false
        state.error = action.payload
      })

      .addCase(updatePaymentAccount.pending, (state) => {
        state.isUpdatePaymentAccountLoading = true
        state.error = null
        state.updatedPaymentAccountDetail = null
      })
      .addCase(updatePaymentAccount.fulfilled, (state, action) => {
        state.isUpdatePaymentAccountLoading = false
        state.updatedPaymentAccountDetail = action.payload
        state.paymentAccounts = state.paymentAccounts.map((paymentAccount) =>
          paymentAccount._id === action.payload._id ? action.payload : paymentAccount
        )
      })
      .addCase(updatePaymentAccount.rejected, (state, action) => {
        state.isUpdatePaymentAccountLoading = false
        state.error = action.payload
      })
      
      .addCase(deletePaymentAccount.pending, (state) => {
        state.isDeletingPaymentAccountLoading = true
        state.error = null
        state.deletedPaymentAccountDetail = null
      })
      .addCase(deletePaymentAccount.fulfilled, (state, action) => {
        const deletedPaymentAccountId = action.payload?._id

        if (deletedPaymentAccountId) {
          state.paymentAccounts = state.paymentAccounts.filter(
            (paymentAccount) => paymentAccount._id !== deletedPaymentAccountId
          )
        }
        state.deletedPaymentAccountDetail = action.payload
        state.isDeletingPaymentAccountLoading = false
      })
      .addCase(deletePaymentAccount.rejected, (state, action) => {
        state.isDeletingPaymentAccountLoading = false
        state.error = action.payload
        state.deletedPaymentAccountDetail = null
      })
  },
})

// Export the actions to be used in components
export const {
  clearDerliveryPrices,
  clearAddedDefaultDeliveryDay,
  clearError,
  clearAddedPaymentAccount,
  clearUpdatedPaymentAccount,
  clearDeletedPaymentAccount,
  clearPaymentAccounts,
} = configSlice.actions

// Export the reducer to be used in the store
export default configSlice.reducer
