import fetch from 'auth/FetchInterceptor';

const ProductService = {};

ProductService.getProducts = function (params) {
  return fetch({
    url: '/product/filter',
    method: 'get',
    params,
  });
};

ProductService.getProductDetail = function (id) {
  return fetch({
    url: '/product/' + id,
    method: 'get',
  });
};

ProductService.addProduct = function (data) {
  return fetch({
    url: '/product',
    method: 'post',
    data: data,
  });
};

ProductService.deleteProduct = function (id) {
  return fetch({
    url: '/product/' + id,
    method: 'delete',
  });
};

ProductService.editProduct = function (body, id) {
  return fetch({
    url: '/product/' + id,
    method: 'put',
    data: body,
  });
};

ProductService.getTotalProductsCount = function (params) {
  return fetch({
    url: '/product/total-products-count',
    method: 'get',
    params,
  });
};

export default ProductService;
