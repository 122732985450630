import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import SubCategoryService from 'services/SubCategoryService';
import Utils from 'utils';

export const initialState = {
  loading: false,
  isSubCategoriesLoading: false,
  isAddingSubCategoryLoading: false,
  isDeletingSubCategoryLoading: false,
  isEditingSubCategoryLoading: false,
  subCategoryDetail: null,
  subCategories: [],
  filteredSubCategories: [],
  error: null,
};

export const getSubCategories = createAsyncThunk(
  'subCategories/list',
  async (data, { rejectWithValue }) => {
    try {
      const response = await SubCategoryService.getSubCategories();
      console.log('response subCategories : ', response);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

export const getSubCategoriesByCategory = createAsyncThunk(
  'subCategories/byCategory/list',
  async (id, { rejectWithValue }) => {
    try {
      const response = await SubCategoryService.getSubCategoriesByCategory(id);
      console.log('response subCategories : ', response);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

export const filterSubCategories = createAsyncThunk(
  'subCategories/filter',
  async ({ searchArray, value, fromApi, data = {} }, { rejectWithValue }) => {
    try {
      if (fromApi) {
        const response = await SubCategoryService.filterSubCategories(data);
        return response;
      } else {
        const response = Utils.wildCardSearchUsingName(searchArray, value);
        return response;
      }
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

export const addSubCategories = createAsyncThunk(
  'subCategories/add',
  async (data, { rejectWithValue }) => {
    try {
      const response = await SubCategoryService.addSubCategories(data);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

export const editSubCategories = createAsyncThunk(
  'subCategories/edit',
  async (data, { rejectWithValue }) => {
    try {
      console.log('data ,,, ,, ', data);
      const response = await SubCategoryService.editSubCategories(
        data.body,
        data.id
      );
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

export const deleteSubCategory = createAsyncThunk(
  'subCategories/delete',
  async (id, { rejectWithValue }) => {
    try {
      const response = await SubCategoryService.deleteSubCategories(id);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

// Slice
const subCategorySlice = createSlice({
  name: 'subCategories',
  initialState,
  reducers: {
    clearSubCategories(state) {
      state.subCategories = [];
    },
    clearError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSubCategories.pending, (state) => {
        state.isSubCategoriesLoading = true;
        state.error = null;
        state.subCategoryDetail = null;
      })
      .addCase(getSubCategories.fulfilled, (state, action) => {
        state.isSubCategoriesLoading = false;
        state.subCategories = action.payload;
        state.subCategoryDetail = null;
      })
      .addCase(getSubCategories.rejected, (state, action) => {
        state.isSubCategoriesLoading = false;
        state.error = action.payload;
        state.subCategoryDetail = null;
      })
      .addCase(getSubCategoriesByCategory.pending, (state) => {
        state.isSubCategoriesLoading = true;
        state.error = null;
        state.subCategoryDetail = null;
      })
      .addCase(getSubCategoriesByCategory.fulfilled, (state, action) => {
        state.isSubCategoriesLoading = false;
        state.subCategories = action.payload;
        state.subCategoryDetail = null;
      })
      .addCase(getSubCategoriesByCategory.rejected, (state, action) => {
        state.isSubCategoriesLoading = false;
        state.error = action.payload;
        state.subCategoryDetail = null;
      })
      .addCase(filterSubCategories.pending, (state) => {
        state.isSubCategoriesLoading = true;
        state.error = null;
        state.subCategoryDetail = null;
      })
      .addCase(filterSubCategories.fulfilled, (state, action) => {
        state.isSubCategoriesLoading = false;
        state.filteredSubCategories = action.payload;
        state.subCategoryDetail = null;
      })
      .addCase(filterSubCategories.rejected, (state, action) => {
        state.isSubCategoriesLoading = false;
        state.error = action.payload;
        state.filteredSubCategories = state.subCategories;
        state.subCategoryDetail = null;
      })
      .addCase(addSubCategories.pending, (state) => {
        state.isAddingSubCategoryLoading = true;
        state.error = null;
        state.subCategoryDetail = null;
      })
      .addCase(addSubCategories.fulfilled, (state, action) => {
        state.isAddingSubCategoryLoading = false;
        state.subCategoryDetail = action.payload;
      })
      .addCase(addSubCategories.rejected, (state, action) => {
        state.isAddingSubCategoryLoading = false;
        state.error = action.payload;
      })
      .addCase(editSubCategories.pending, (state) => {
        state.isEditingSubCategoryLoading = true;
        state.error = null;
        state.subCategoryDetail = null;
      })
      .addCase(editSubCategories.fulfilled, (state, action) => {
        state.isEditingSubCategoryLoading = false;
        state.subCategoryDetail = action.payload;
      })
      .addCase(editSubCategories.rejected, (state, action) => {
        state.isEditingSubCategoryLoading = false;
        state.error = action.payload;
      })
      .addCase(deleteSubCategory.pending, (state) => {
        state.isDeletingSubCategoryLoading = true;
        state.error = null;
      })
      .addCase(deleteSubCategory.fulfilled, (state, action) => {
        state.isDeletingSubCategoryLoading = false;

        const deletedSubCategoryId =
          action.payload &&
          action.payload.subCategory &&
          action.payload.subCategory._id;
        state.subCategories = state.subCategories.filter(
          (subCategory) => subCategory._id !== deletedSubCategoryId
        );
        state.filteredSubCategories =
          state.filteredSubCategories &&
          state.filteredSubCategories.filter(
            (subCategory) => subCategory._id !== deletedSubCategoryId
          );
      })
      .addCase(deleteSubCategory.rejected, (state, action) => {
        state.isDeletingSubCategoryLoading = false;
        state.error = action.payload;
      });
  },
});

// Export the actions to be used in components
export const { clearSubCategories, clearError } = subCategorySlice.actions;

// Export the reducer to be used in the store
export default subCategorySlice.reducer;
