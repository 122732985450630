import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN, USER } from 'constants/AuthConstant';
import AuthService from 'services/AuthService';
import parsePhoneNumber from 'utils/parsePhoneNumber';

export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  adminDetail: null,
  isAdminDetailLoading: false,
  updatedAdminDetail: null,
  isUpdateAdminDetailLoading: false,
  updateError: null,
  deleteError: null,
  addError: null,
  redirect: "",
  token: localStorage.getItem(AUTH_TOKEN) || null,
  user: (() => {
    const storedUser = localStorage.getItem(USER)
    try {
      const parsedUser = JSON.parse(storedUser)
      if (typeof parsedUser === "object" && parsedUser !== null) {
        return parsedUser
      }
    } catch (error) {
      console.error("Error parsing user from localStorage:", error)
    }
    return null;
  })(),
};

export const signIn = createAsyncThunk(
  "auth/signIn",
  async (data, { rejectWithValue }) => {
    const { phone, password } = data;
    try {
      const response = await AuthService.login({
        phone: parsePhoneNumber(phone),
        password: password,
      });
      if (response.token) {
        const token = response.token.accessToken;
        const user = response.user;
        console.log("token token : ", token);
        localStorage.setItem(AUTH_TOKEN, token);
        localStorage.setItem(USER, JSON.stringify(user));
        return { token, user };
      } else {
        return rejectWithValue("Token not found");
      }
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const signUp = createAsyncThunk(
  "auth/signUp",
  async (data, { rejectWithValue }) => {
    const { email, password } = data;
    try {
      const response = await AuthService.register(email, password);
      if (response.user) {
        const token = response.user.refreshToken;
        const user = response.user;
        localStorage.setItem(AUTH_TOKEN, response.user.refreshToken);
        localStorage.setItem(USER, JSON.stringify(user));
        return { token, user };
      } else {
        return rejectWithValue(response.message?.replace("Firebase: ", ""));
      }
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const signOut = createAsyncThunk("auth/signOut", async () => {
  // const response = await AuthService.signOutRequest();
  localStorage.removeItem(AUTH_TOKEN);
  localStorage.removeItem(USER);
  // return response.data;
});

export const getAdminProfile = createAsyncThunk(
  "admin/profile",
  async (id, { rejectWithValue }) => {
    try {
      const response = await AuthService.getAdminProfile(id);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const updateAdminProfile = createAsyncThunk(
  "admin/update",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AuthService.updateAdminProfile(data);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authenticated: (state, action) => {
      state.loading = false;
      state.redirect = "/";
      state.token = action.payload;
    },
    showAuthMessage: (state, action) => {
      state.message = action.payload;
      state.showMessage = true;
      state.loading = false;
    },
    hideAuthMessage: (state) => {
      state.message = "";
      state.showMessage = false;
    },
    signOutSuccess: (state) => {
      state.loading = false;
      state.token = null;
      state.redirect = "/";
    },
    showLoading: (state) => {
      state.loading = true;
    },
    signInSuccess: (state, action) => {
      state.loading = false;
      state.token = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.pending, (state) => {
        state.loading = true;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.token = action.payload && action.payload.token;
        state.user = action.payload && action.payload.user;
      })
      .addCase(signIn.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(signOut.fulfilled, (state) => {
        state.loading = false;
        state.token = null;
        state.user = null;
        state.redirect = "/";
      })
      .addCase(signOut.rejected, (state) => {
        state.loading = false;
        state.token = null;
        state.redirect = "/";
      })
      .addCase(signUp.pending, (state) => {
        state.loading = true;
      })
      .addCase(signUp.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.token = action.payload && action.payload.token;
        state.user = action.payload && action.payload.user;
      })
      .addCase(signUp.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(getAdminProfile.pending, (state) => {
        state.isAdminDetailLoading = true;
        state.error = null;
        state.adminDetail = null;
      })
      .addCase(getAdminProfile.fulfilled, (state, action) => {
        state.isAdminDetailLoading = false;
        state.adminDetail = action.payload;
      })
      .addCase(getAdminProfile.rejected, (state, action) => {
        state.isAdminDetailLoading = false;
        state.error = action.payload;
        state.adminDetail = null;
      })
      .addCase(updateAdminProfile.pending, (state) => {
        state.isUpdateAdminDetailLoading = true;
        state.updateError = null;
        state.updatedAdminDetail = null;
      })
      .addCase(updateAdminProfile.fulfilled, (state, action) => {
        state.isUpdateAdminDetailLoading = false;
        state.updatedAdminDetail = action.payload;
        state.updateError = null;
      })
      .addCase(updateAdminProfile.rejected, (state, action) => {
        state.isUpdateAdminDetailLoading = false;
        state.updateError = action.payload;
        state.updatedAdminDetail = null;
      });
  },
});

export const {
  authenticated,
  showAuthMessage,
  hideAuthMessage,
  signOutSuccess,
  showLoading,
  signInSuccess,
} = authSlice.actions;

export default authSlice.reducer