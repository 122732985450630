import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import UserService from 'services/UserService';

export const initialState = {
  users: [],
  managers: [],
  admins: [],
  loading: false,
  isManagersLoading: false,

  userDetail: null,
  isUsersLoading: false,

  managerDetail: null,
  isManagerDetailLoading: false,

  adminDetail: null,
  isAdminsLoading: false,

  updatedManagerDetail: null,
  isUpdateManagerDetailLoading: false,
  addedManagerDetail: null,
  isAddManagerDetailLoading: false,

  isDeleteManagerLoading: false,
  deletedManagerDetail: null,

  error: null,
  totalUsersCount: 'N/A',
  recentlyJoinedUsers: [],
  isRecentlyJoinedUsersLoading: false,

  updateError: null,
  deleteError: null,
  addError: null,

  // DeliveryPerson
  deliveryPersons: [],
  isDeliveryPersonsLoading: false,
  isDeliveryPersonDetailLoading: false,
  isDeleteDeliveryPersonLoading: false,
  isAddDeliveryPersonDetailLoading: false,
  isUpdateDeliveryPersonDetailLoading: false,
  deliveryPersonDetail: null,
  updatedDeliveryPersonDetail: null,
  addedDeliveryPersonDetail: null,
  deletedDeliveryPersonDetail: null,
};

export const getUsers = createAsyncThunk(
  'users/list',
  async (data = {}, { rejectWithValue }) => {
    try {
      const response = await UserService.getUsers(data);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

export const getManagers = createAsyncThunk(
  'managers/list',
  async (data = {}, { rejectWithValue }) => {
    try {
      const response = await UserService.getUsers(data);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

export const getManagerProfile = createAsyncThunk(
  'manager/profile',
  async (id, { rejectWithValue }) => {
    try {
      const response = await UserService.getManagerProfile(id);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

export const deleteManagerProfile = createAsyncThunk(
  'manager/delete',
  async (id, { rejectWithValue }) => {
    try {
      const response = await UserService.deleteManagerProfile(id);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

export const updateManagerProfile = createAsyncThunk(
  'manager/update',
  async (data, { rejectWithValue }) => {
    try {
      const response = await UserService.updateManagerProfile(
        data.id,
        data.body
      );
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

export const addManagerProfile = createAsyncThunk(
  'manager/add',
  async (data, { rejectWithValue }) => {
    console.log('data in add: ', data);

    try {
      const response = await UserService.addManagerProfile(data.body);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

export const getDeliveryPersons = createAsyncThunk(
  'deliveryPerson/list',
  async (data = {}, { rejectWithValue }) => {
    try {
      const response = await UserService.getUsers(data);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

export const getDeliveryPersonProfile = createAsyncThunk(
  'deliveryPerson/profile',
  async (id, { rejectWithValue }) => {
    try {
      const response = await UserService.getDeliveryPersonProfile(id);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

export const deleteDeliveryPersonProfile = createAsyncThunk(
  'deliveryPerson/delete',
  async (id, { rejectWithValue }) => {
    try {
      const response = await UserService.deleteDeliveryPersonProfile(id);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

export const updateDeliveryPersonProfile = createAsyncThunk(
  'deliveryPerson/update',
  async (data, { rejectWithValue }) => {
    try {
      const response = await UserService.updateDeliveryPersonProfile(
        data.id,
        data.body
      );
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

export const addDeliveryPersonProfile = createAsyncThunk(
  'deliveryPerson/add',
  async (data, { rejectWithValue }) => {
    console.log('data in add: ', data);

    try {
      const response = await UserService.addDeliveryPersonProfile(data.body);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

export const getAdmins = createAsyncThunk(
  'admins/list',
  async (data = {}, { rejectWithValue }) => {
    try {
      const response = await UserService.getUsers(data);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

export const getRecentlyJoinedUsers = createAsyncThunk(
  'users/recently-joined',
  async (data = {}, { rejectWithValue }) => {
    try {
      const response = await UserService.getRecentlyJoinedUsers(data);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

// Slice
const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    clearUsers(state) {
      state.users = [];
    },
    clearError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state) => {
        state.isUsersLoading = true;
        state.error = null;
        state.userDetail = null;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.isUsersLoading = false;
        state.users = action.payload;
        state.userDetail = null;
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.isUsersLoading = false;
        state.error = action.payload;
        state.userDetail = null;
      })
      .addCase(getManagers.pending, (state) => {
        state.isManagersLoading = true;
        state.error = null;
        state.managerDetail = null;
        state.isManagerDetailLoading = false;
        state.updatedManagerDetail = null;
        state.isUpdateManagerDetailLoading = false;
        state.addedManagerDetail = null;
        state.isAddManagerDetailLoading = false;
        state.isDeleteManagerLoading = false;
        state.deletedManagerDetail = null;
        state.updateError = null;
        state.deleteError = null;
        state.addError = null;
      })
      .addCase(getManagers.fulfilled, (state, action) => {
        state.isManagersLoading = false;
        state.managers = action.payload;
        state.managerDetail = null;
      })
      .addCase(getManagers.rejected, (state, action) => {
        state.isManagersLoading = false;
        state.error = action.payload;
        state.managerDetail = null;
      })
      .addCase(getManagerProfile.pending, (state) => {
        state.isManagerDetailLoading = true;
        state.error = null;
        state.managerDetail = null;
      })
      .addCase(getManagerProfile.fulfilled, (state, action) => {
        state.isManagerDetailLoading = false;
        state.managerDetail = action.payload;
      })
      .addCase(getManagerProfile.rejected, (state, action) => {
        state.isManagerDetailLoading = false;
        state.error = action.payload;
        state.managerDetail = null;
      })
      .addCase(deleteManagerProfile.pending, (state) => {
        state.isDeleteManagerLoading = true;
        state.deleteError = null;
        state.deletedManagerDetail = null;
      })
      .addCase(deleteManagerProfile.fulfilled, (state, action) => {
        state.isDeleteManagerLoading = false;
        state.deleteError = null;
        state.deletedManagerDetail = action.payload;
      })
      .addCase(deleteManagerProfile.rejected, (state, action) => {
        state.isDeleteManagerLoading = false;
        state.deleteError = action.payload;
        state.deletedManagerDetail = null;
      })
      .addCase(updateManagerProfile.pending, (state) => {
        state.isUpdateManagerDetailLoading = true;
        state.updateError = null;
        state.updatedManagerDetail = null;
      })
      .addCase(updateManagerProfile.fulfilled, (state, action) => {
        state.isUpdateManagerDetailLoading = false;
        state.updatedManagerDetail = action.payload;
        state.updateError = null;
      })
      .addCase(updateManagerProfile.rejected, (state, action) => {
        state.isUpdateManagerDetailLoading = false;
        state.updateError = action.payload;
        state.updatedManagerDetail = null;
      })
      .addCase(addManagerProfile.pending, (state) => {
        state.isAddManagerDetailLoading = true;
        state.addError = null;
        state.addedManagerDetail = null;
      })
      .addCase(addManagerProfile.fulfilled, (state, action) => {
        state.isAddManagerDetailLoading = false;
        state.addedManagerDetail = action.payload;
        state.addError = null;
      })
      .addCase(addManagerProfile.rejected, (state, action) => {
        state.isAddManagerDetailLoading = false;
        state.addError = action.payload;
        state.addedManagerDetail = null;
      })
      .addCase(getDeliveryPersons.pending, (state) => {
        state.isDeliveryPersonsLoading = true;
        state.error = null;
        state.deliveryPersonDetail = null;
        state.isDeliveryPersonDetailLoading = false;
        state.updatedDeliveryPersonDetail = null;
        state.isUpdateDeliveryPersonDetailLoading = false;
        state.addedDeliveryPersonDetail = null;
        state.isAddDeliveryPersonDetailLoading = false;
        state.isDeleteDeliveryPersonLoading = false;
        state.deletedDeliveryPersonDetail = null;
        state.updateError = null;
        state.deleteError = null;
        state.addError = null;
      })
      .addCase(getDeliveryPersons.fulfilled, (state, action) => {
        state.isDeliveryPersonsLoading = false;
        state.deliveryPersons = action.payload;
        state.deliveryPersonDetail = null;
      })
      .addCase(getDeliveryPersons.rejected, (state, action) => {
        state.isDeliveryPersonsLoading = false;
        state.error = action.payload;
        state.deliveryPersonDetail = null;
      })
      .addCase(getDeliveryPersonProfile.pending, (state) => {
        state.isDeliveryPersonDetailLoading = true;
        state.error = null;
        state.deliveryPersonDetail = null;
      })
      .addCase(getDeliveryPersonProfile.fulfilled, (state, action) => {
        state.isDeliveryPersonDetailLoading = false;
        state.deliveryPersonDetail = action.payload;
      })
      .addCase(getDeliveryPersonProfile.rejected, (state, action) => {
        state.isDeliveryPersonDetailLoading = false;
        state.error = action.payload;
        state.deliveryPersonDetail = null;
      })
      .addCase(deleteDeliveryPersonProfile.pending, (state) => {
        state.isDeleteDeliveryPersonLoading = true;
        state.deleteError = null;
        state.deletedDeliveryPersonDetail = null;
      })
      .addCase(deleteDeliveryPersonProfile.fulfilled, (state, action) => {
        state.isDeleteDeliveryPersonLoading = false;
        state.deleteError = null;
        state.deletedDeliveryPersonDetail = action.payload;
      })
      .addCase(deleteDeliveryPersonProfile.rejected, (state, action) => {
        state.isDeleteDeliveryPersonLoading = false;
        state.deleteError = action.payload;
        state.deletedDeliveryPersonDetail = null;
      })
      .addCase(updateDeliveryPersonProfile.pending, (state) => {
        state.isUpdateDeliveryPersonDetailLoading = true;
        state.updateError = null;
        state.updatedDeliveryPersonDetail = null;
      })
      .addCase(updateDeliveryPersonProfile.fulfilled, (state, action) => {
        state.isUpdateDeliveryPersonDetailLoading = false;
        state.updatedDeliveryPersonDetail = action.payload;
        state.updateError = null;
      })
      .addCase(updateDeliveryPersonProfile.rejected, (state, action) => {
        state.isUpdateDeliveryPersonDetailLoading = false;
        state.updateError = action.payload;
        state.updatedDeliveryPersonDetail = null;
      })
      .addCase(addDeliveryPersonProfile.pending, (state) => {
        state.isAddDeliveryPersonDetailLoading = true;
        state.addError = null;
        state.addedDeliveryPersonDetail = null;
      })
      .addCase(addDeliveryPersonProfile.fulfilled, (state, action) => {
        state.isAddDeliveryPersonDetailLoading = false;
        state.addedDeliveryPersonDetail = action.payload;
        state.addError = null;
      })
      .addCase(addDeliveryPersonProfile.rejected, (state, action) => {
        state.isAddDeliveryPersonDetailLoading = false;
        state.addError = action.payload;
        state.addedDeliveryPersonDetail = null;
      })
      .addCase(getAdmins.pending, (state) => {
        state.isAdminsLoading = true;
        state.error = null;
        state.adminDetail = null;
      })
      .addCase(getAdmins.fulfilled, (state, action) => {
        state.isAdminsLoading = false;
        state.admins = action.payload;
        state.adminDetail = null;
      })
      .addCase(getAdmins.rejected, (state, action) => {
        state.isAdminsLoading = false;
        state.error = action.payload;
        state.adminDetail = null;
      })
      .addCase(getRecentlyJoinedUsers.pending, (state) => {
        state.isRecentlyJoinedUsersLoading = true;
        state.recentlyJoinedUsers = [];
      })
      .addCase(getRecentlyJoinedUsers.fulfilled, (state, action) => {
        state.isRecentlyJoinedUsersLoading = false;
        state.recentlyJoinedUsers = action.payload.recentlyJoinedUsers;
        state.totalUsersCount = action.payload.totalUsersCount;
      })
      .addCase(getRecentlyJoinedUsers.rejected, (state, action) => {
        state.isRecentlyJoinedUsersLoading = false;
        state.recentlyJoinedUsers = [];
      });
  },
});

// Export the actions to be used in components
export const { clearUsers, clearError } = userSlice.actions;

// Export the reducer to be used in the store
export default userSlice.reducer;
