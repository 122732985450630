import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import BannerService from 'services/BannerService';
import Utils from 'utils';

export const initialState = {
  loading: false,
  isBannersLoading: false,
  isAddingBannerLoading: false,
  isDeletingBannerLoading: false,
  isEditingBannerLoading: false,
  bannerDetail: null,
  banners: [],
  filteredBanners: [],
  error: null,
};

export const getBanners = createAsyncThunk(
  'banners/list',
  async (data, { rejectWithValue }) => {
    try {
      const response = await BannerService.getBanners();
      console.log('response banners : ', response);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

export const filterBanners = createAsyncThunk(
  'banners/filter',
  async ({ searchArray, value, fromApi, data = {} }, { rejectWithValue }) => {
    try {
      if (fromApi) {
        const response = await BannerService.filterBanners(data);
        return response;
      } else {
        const response = Utils.wildCardSearchUsingName(
          searchArray,
          value,
          'title'
        );
        return response;
      }
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

export const addBanner = createAsyncThunk(
  'banners/add',
  async (data, { rejectWithValue }) => {
    try {
      const response = await BannerService.addBanners(data);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

export const editBanner = createAsyncThunk(
  'banners/edit',
  async (data, { rejectWithValue }) => {
    try {
      console.log('data ,,, ,, ', data);
      const response = await BannerService.editBanners(data.body, data.id);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

export const deleteBanner = createAsyncThunk(
  'banners/delete',
  async (id, { rejectWithValue }) => {
    try {
      const response = await BannerService.deleteBanners(id);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

// Slice
const bannerSlice = createSlice({
  name: 'banners',
  initialState,
  reducers: {
    clearBanners(state) {
      state.banners = [];
    },
    clearError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBanners.pending, (state) => {
        state.isBannersLoading = true;
        state.error = null;
        state.bannerDetail = null;
      })
      .addCase(getBanners.fulfilled, (state, action) => {
        state.isBannersLoading = false;
        state.banners = action.payload;
        state.bannerDetail = null;
      })
      .addCase(getBanners.rejected, (state, action) => {
        state.isBannersLoading = false;
        state.error = action.payload;
        state.bannerDetail = null;
      })
      .addCase(filterBanners.pending, (state) => {
        state.isBannersLoading = true;
        state.error = null;
        state.bannerDetail = null;
      })
      .addCase(filterBanners.fulfilled, (state, action) => {
        state.isBannersLoading = false;
        state.filteredBanners = action.payload;
        state.bannerDetail = null;
      })
      .addCase(filterBanners.rejected, (state, action) => {
        state.isBannersLoading = false;
        state.error = action.payload;
        state.filteredBanners = state.banners;
        state.bannerDetail = null;
      })
      .addCase(addBanner.pending, (state) => {
        state.isAddingBannerLoading = true;
        state.error = null;
        state.bannerDetail = null;
      })
      .addCase(addBanner.fulfilled, (state, action) => {
        state.isAddingBannerLoading = false;
        state.bannerDetail = action.payload;
      })
      .addCase(addBanner.rejected, (state, action) => {
        state.isAddingBannerLoading = false;
        state.error = action.payload;
      })
      .addCase(editBanner.pending, (state) => {
        state.isEditingBannerLoading = true;
        state.error = null;
        state.bannerDetail = null;
      })
      .addCase(editBanner.fulfilled, (state, action) => {
        state.isEditingBannerLoading = false;
        state.bannerDetail = action.payload;
      })
      .addCase(editBanner.rejected, (state, action) => {
        state.isEditingBannerLoading = false;
        state.error = action.payload;
      })
      .addCase(deleteBanner.pending, (state) => {
        state.isDeletingBannerLoading = true;
        state.error = null;
      })
      .addCase(deleteBanner.fulfilled, (state, action) => {
        state.isDeletingBannerLoading = false;

        const deletedBannerId =
          action.payload && action.payload.banner && action.payload.banner._id;
        state.banners = state.banners.filter(
          (banner) => banner._id !== deletedBannerId
        );
        state.filteredBanners =
          state.filteredBanners &&
          state.filteredBanners.filter(
            (banner) => banner._id !== deletedBannerId
          );
      })
      .addCase(deleteBanner.rejected, (state, action) => {
        state.isDeletingBannerLoading = false;
        state.error = action.payload;
      });
  },
});

// Export the actions to be used in components
export const { clearBanners, clearError } = bannerSlice.actions;

// Export the reducer to be used in the store
export default bannerSlice.reducer;
