import fetch from 'auth/FetchInterceptor';

const OrderService = {};

OrderService.getOrders = function (params) {
  return fetch({
    url: '/order/filter',
    method: 'get',
    params,
  });
};

OrderService.getOrderDetail = function (id) {
  return fetch({
    url: '/order/detail/' + id,
    method: 'get',
  });
};

OrderService.addOrder = function (data) {
  return fetch({
    url: '/order',
    method: 'post',
    data: data,
  });
};

OrderService.deleteOrder = function (id) {
  return fetch({
    url: '/order/' + id,
    method: 'delete',
  });
};

OrderService.editOrder = function (body, id) {
  return fetch({
    url: '/order/status/' + id,
    method: 'put',
    data: body,
  });
};

OrderService.getOrdersCountAndRecentOrders = function () {
  return fetch({
    url: '/order/counts-and-recent-orders',
    method: 'get',
  });
};

OrderService.assignDeliveryPerson = function (data) {
  return fetch({
    url: '/order/delivery-person/assign',
    method: 'post',
    data: data,
  });
};

OrderService.updatePayment = function (id, body) {
  return fetch({
    url: '/order/payment/' + id,
    method: 'put',
    data: body,
  });
};

export default OrderService;
