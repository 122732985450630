import fetch from 'auth/FetchInterceptor';

const ColorService = {};

ColorService.getColors = function () {
  return fetch({
    url: '/color',
    method: 'get',
  });
};

ColorService.filterColors = function (params) {
  return fetch({
    url: '/color/filter',
    method: 'get',
    params: params,
  });
};

ColorService.addColors = function (data) {
  return fetch({
    url: '/color',
    method: 'post',
    data: data,
  });
};

ColorService.deleteColors = function (id) {
  return fetch({
    url: '/color/' + id,
    method: 'delete',
  });
};

ColorService.editColors = function (body, id) {
  return fetch({
    url: '/color/' + id,
    method: 'put',
    data: body,
  });
};

export default ColorService;
