const parsePhoneNumber = (phoneNumber) => {
  phoneNumber = phoneNumber.replace(/\D/g, '');

  if (phoneNumber.startsWith('251')) {
    phoneNumber = '0' + phoneNumber.substring(3);
    return phoneNumber;
  }
  if (phoneNumber.startsWith('09') && phoneNumber.length === 10) {
    return phoneNumber;
  }
  if (phoneNumber.startsWith('9')) {
    return '0' + phoneNumber;
  }
  if (phoneNumber.startsWith('07') && phoneNumber.length === 10) {
    return phoneNumber;
  }
  if (phoneNumber.startsWith('7')) {
    return '0' + phoneNumber;
  } else {
    return phoneNumber;
  }
};

module.exports = parsePhoneNumber;
