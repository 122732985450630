import fetch from 'auth/FetchInterceptor';

const NotificationService = {};

NotificationService.getNotifications = function () {
  return fetch({
    url: '/notification',
    method: 'get',
  });
};

NotificationService.filterNotifications = function (params) {
  return fetch({
    url: '/notification/filter',
    method: 'get',
    params: params,
  });
};

NotificationService.addNotifications = function (data) {
  return fetch({
    url: '/notification',
    method: 'post',
    data: data,
  });
};

NotificationService.addNotificationToAll = function (data) {
  return fetch({
    url: '/notification/all',
    method: 'post',
    data: data,
  });
};

NotificationService.deleteNotifications = function (id) {
  return fetch({
    url: '/notification/' + id,
    method: 'delete',
  });
};

NotificationService.editNotifications = function (body, id) {
  return fetch({
    url: '/notification/' + id,
    method: 'put',
    data: body,
  });
};

export default NotificationService;
