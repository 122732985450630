import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import ProductService from 'services/ProductService';
import isEmpty from 'utils/isEmpty';

export const initialState = {
  loading: false,
  products: [],
  isAddingProductLoading: false,
  productDetail: null,
  product: null,
  loadingProduct: false,
  productError: null,
  isEditingProductLoading: false,
  isDeletingProductLoading: false,
  isProductsLoading: false,
  error: null,
  totalProductsCount: 'N/A',
  deletedProductDetail: null,
}
const defaultDataParam = {
  page: 1,
}

export const getProducts = createAsyncThunk(
  'product/list',
  async (data = defaultDataParam, { rejectWithValue }) => {
    try {
      const response = await ProductService.getProducts(data)
      return response
    } catch (err) {
      return rejectWithValue(err.message || 'Error')
    }
  }
)
export const getProductDetail = createAsyncThunk(
  'product/detail',
  async (id, { rejectWithValue }) => {
    try {
      const response = await ProductService.getProductDetail(id)
      return response
    } catch (err) {
      return rejectWithValue(err.message || 'Error')
    }
  }
)

export const addProduct = createAsyncThunk(
  'product/add',
  async (data, { rejectWithValue }) => {
    try {
      const response = await ProductService.addProduct(data)
      return response
    } catch (err) {
      // var errorText = 'Error'
      // if (!isEmpty(err.message)) {
      //   errorText = err.message
      // } else if (!isEmpty(err.respose.message)) {
      //   errorText = err.respose.message
      // }
      return rejectWithValue(err)
    }
  }
)

export const editProduct = createAsyncThunk(
  'product/edit',
  async (data, { rejectWithValue }) => {
    try {
      const response = await ProductService.editProduct(data.body, data.id)
      return response
    } catch (err) {
      console.log('error error error  AA err: ', err)

      return rejectWithValue(err || 'Error')
    }
  }
)

export const deleteProduct = createAsyncThunk(
  'product/delete',
  async (id, { rejectWithValue }) => {
    try {
      const response = await ProductService.deleteProduct(id)
      return response
    } catch (err) {
      return rejectWithValue(err.message || 'Error')
    }
  }
)

export const getTotalProductsCount = createAsyncThunk(
  'product/products-count',
  async (data, { rejectWithValue }) => {
    try {
      const response = await ProductService.getTotalProductsCount()
      return response
    } catch (err) {
      return rejectWithValue(err.message || 'Error')
    }
  }
)

// Slice
const productSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    clearError(state) {
      state.error = null
    },
    clearDeletedProductDetail(state) {
      state.deletedProductDetail = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProducts.pending, (state) => {
        state.loading = true
        state.error = null
        state.isAddingProductLoading = false
        state.productDetail = null
        state.isEditingProductLoading = false
        state.isDeletingProductLoading = false
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        state.loading = false
        state.products = action.payload
      })
      .addCase(getProducts.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase(getProductDetail.pending, (state) => {
        state.product = null
        state.loadingProduct = true
        state.productError = null
        state.error = null
      })
      .addCase(getProductDetail.fulfilled, (state, action) => {
        state.loadingProduct = false
        state.product = action.payload
        state.error = null
      })
      .addCase(getProductDetail.rejected, (state, action) => {
        state.loadingProduct = false
        state.productError = action.payload
        state.error = null
      })
      .addCase(addProduct.pending, (state) => {
        state.isAddingProductLoading = true
        state.error = null
        state.productDetail = null
      })
      .addCase(addProduct.fulfilled, (state, action) => {
        state.isAddingProductLoading = false
        state.productDetail = action.payload
      })
      .addCase(addProduct.rejected, (state, action) => {
        state.isAddingProductLoading = false
        state.error = action.payload?.response?.data
      })
      .addCase(editProduct.pending, (state) => {
        state.isEditingProductLoading = true
        state.error = null
        state.productDetail = null
      })
      .addCase(editProduct.fulfilled, (state, action) => {
        state.isEditingProductLoading = false
        state.productDetail = action.payload
      })
      .addCase(editProduct.rejected, (state, action) => {
        state.isEditingProductLoading = false
        state.error = action.payload?.response?.data
      })
      .addCase(deleteProduct.pending, (state) => {
        state.isDeletingProductLoading = true
        state.error = null
        state.deletedProductDetail = null
      })
      .addCase(deleteProduct.fulfilled, (state, action) => {
        state.isDeletingProductLoading = false
        state.deletedProductDetail = action.payload
        const deletedProductId =
          action.payload && action.payload.product && action.payload.product._id
        state.products.results = state.products.results.filter(
          (product) => product._id !== deletedProductId
        )
      })
      .addCase(deleteProduct.rejected, (state, action) => {
        state.isDeletingProductLoading = false
        state.error = action.payload
      })
      .addCase(getTotalProductsCount.pending, (state) => {
        state.isTotalProductsCountLoading = true
      })
      .addCase(getTotalProductsCount.fulfilled, (state, action) => {
        state.isTotalProductsCountLoading = false
        state.totalProductsCount = action.payload
      })
      .addCase(getTotalProductsCount.rejected, (state) => {
        state.isTotalProductsCountLoading = false
        state.totalProductsCount = 'N/A'
      })
  },
})

// Export the actions to be used in components
export const { clearError, clearDeletedProductDetail } = productSlice.actions

// Export the reducer to be used in the store
export default productSlice.reducer;
