import fetch from 'auth/FetchInterceptor'

const AuthService = {}

AuthService.login = function (data) {
  return fetch({
    url: '/auth/loginAdmin',
    method: 'post',
    data: data,
  });
}

AuthService.register = function (data) {
  return fetch({
    url: "/auth/admin/register",
    method: "post",
    data: data,
  });
};

AuthService.updateAdminProfile = function (data) {
  return fetch({
    url: "/auth/admin/update",
    method: "put",
    data: data,
  });
};

AuthService.getAdminProfile = function (id) {
  return fetch({
    url: "/auth/admin/detail/" + id,
    method: "get",
  });
};

export default AuthService;