import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import OrderService from 'services/OrderService';
import isEmpty from 'utils/isEmpty';

export const initialState = {
  loading: false,
  orders: [],
  isAddingOrderLoading: false,
  isEditingOrderLoading: false,
  isDeletingOrderLoading: false,
  isOrdersLoading: false,
  error: null,
  orderDetail: null,
  orderDetailError: null,
  isOrderDetailLoading: false,

  // For Dashboard
  recentOrders: [],
  ordersCount: {
    pendingOrdersCount: 'N/A',
    canceledOrdersCount: 'N/A',
    deliveringOrdersCount: 'N/A',
    completeOrdersCount: 'N/A',
  },
  isUpdatingPaymentLoading: false,
  updatingPaymentError: null,
  updatingPaymentSuccess: null,
  updatingPaymentMessage: null,
  updatingPaymentData: null,
};
const defaultDataParam = {
  page: 1,
};

export const getOrders = createAsyncThunk(
  'order/list',
  async (data = defaultDataParam, { rejectWithValue }) => {
    try {
      const response = await OrderService.getOrders(data);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

export const assignDeliveryPerson = createAsyncThunk(
  'order/deliveryPerson/assign',
  async (data, { rejectWithValue }) => {
    try {
      const response = await OrderService.assignDeliveryPerson(data);
      return response;
    } catch (err) {
      var errorText = 'Error';
      if (!isEmpty(err.message)) {
        errorText = err.message;
      } else if (!isEmpty(err.respose.message)) {
        errorText = err.respose.message;
      }
      return rejectWithValue(errorText);
    }
  }
);

export const addOrder = createAsyncThunk(
  'order/add',
  async (data, { rejectWithValue }) => {
    try {
      const response = await OrderService.addOrder(data);
      return response;
    } catch (err) {
      var errorText = 'Error';
      if (!isEmpty(err.message)) {
        errorText = err.message;
      } else if (!isEmpty(err.respose.message)) {
        errorText = err.respose.message;
      }
      return rejectWithValue(errorText);
    }
  }
);

export const editOrder = createAsyncThunk(
  'order/edit',
  async (data, { rejectWithValue }) => {
    try {
      const response = await OrderService.editOrder(data.body, data.id);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

export const deleteOrder = createAsyncThunk(
  'order/delete',
  async (id, { rejectWithValue }) => {
    try {
      const response = await OrderService.deleteOrder(id);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

export const getOrderDetail = createAsyncThunk(
  'order/detail',
  async (id, { rejectWithValue }) => {
    try {
      const response = await OrderService.getOrderDetail(id);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

export const getOrdersCountAndRecentOrders = createAsyncThunk(
  'order/recent-orders',
  async (data, { rejectWithValue }) => {
    try {
      const response = await OrderService.getOrdersCountAndRecentOrders();
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

export const updatePayment = createAsyncThunk(
  'order/update-payment',
  async (data, { rejectWithValue }) => {
    try {
      const response = await OrderService.updatePayment(data.id, data.body);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

// Slice
const orderSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrders.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.isAddingOrderLoading = false;
        state.orderDetail = null;
        state.isEditingOrderLoading = false;
        state.isDeletingOrderLoading = false;
      })
      .addCase(getOrders.fulfilled, (state, action) => {
        state.loading = false;
        state.orders = action.payload;
      })
      .addCase(getOrders.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getOrderDetail.pending, (state) => {
        state.orderDetail = null;
        state.orderDetailError = null;
        state.isOrderDetailLoading = true;
      })
      .addCase(getOrderDetail.fulfilled, (state, action) => {
        state.orderDetail = action.payload;
        state.orderDetailError = null;
        state.isOrderDetailLoading = false;
      })
      .addCase(getOrderDetail.rejected, (state, action) => {
        state.orderDetailError = action.payload;
        state.orderDetail = null;
        state.isOrderDetailLoading = false;
      })
      .addCase(addOrder.pending, (state) => {
        state.isAddingOrderLoading = true;
        state.error = null;
        state.orderDetail = null;
      })
      .addCase(addOrder.fulfilled, (state, action) => {
        state.isAddingOrderLoading = false;
        state.orderDetail = action.payload;
      })
      .addCase(addOrder.rejected, (state, action) => {
        state.isAddingOrderLoading = false;
        state.error = action.payload;
      })
      .addCase(editOrder.pending, (state) => {
        state.isEditingOrderLoading = true;
        state.error = null;
      })
      .addCase(editOrder.fulfilled, (state, action) => {
        state.isEditingOrderLoading = false;
        state.orderDetail = action.payload;
      })
      .addCase(editOrder.rejected, (state, action) => {
        state.isEditingOrderLoading = false;
        state.error = action.payload;
      })
      .addCase(assignDeliveryPerson.pending, (state) => {
        state.isEditingOrderLoading = true;
        state.error = null;
      })
      .addCase(assignDeliveryPerson.fulfilled, (state, action) => {
        state.isEditingOrderLoading = false;
        state.orderDetail = action.payload;
      })
      .addCase(assignDeliveryPerson.rejected, (state, action) => {
        state.isEditingOrderLoading = false;
        state.error = action.payload;
      })
      .addCase(deleteOrder.pending, (state) => {
        state.isDeletingOrderLoading = true;
        state.error = null;
      })
      .addCase(deleteOrder.fulfilled, (state, action) => {
        state.isDeletingOrderLoading = false;
        const deletedOrderId =
          action.payload && action.payload.order && action.payload.order._id;
        state.orders.results = state.orders.results.filter(
          (order) => order._id !== deletedOrderId
        );
      })
      .addCase(deleteOrder.rejected, (state, action) => {
        state.isDeletingOrderLoading = false;
        state.error = action.payload;
      })
      .addCase(getOrdersCountAndRecentOrders.pending, (state) => {
        state.isRecentOrdersLoading = true;
      })
      .addCase(getOrdersCountAndRecentOrders.fulfilled, (state, action) => {
        state.recentOrders = action.payload && action.payload.recentOrders;
        state.ordersCount = {
          pendingOrdersCount:
            action.payload &&
            action.payload.counts &&
            action.payload.counts.pendingOrdersCount,
          canceledOrdersCount:
            action.payload &&
            action.payload.counts &&
            action.payload.counts.canceledOrdersCount,
          deliveringOrdersCount:
            action.payload &&
            action.payload.counts &&
            action.payload.counts.deliveringOrdersCount,
          completeOrdersCount:
            action.payload &&
            action.payload.counts &&
            action.payload.counts.completeOrdersCount,
        };
        state.isRecentOrdersLoading = false;
      })
      .addCase(getOrdersCountAndRecentOrders.rejected, (state, action) => {
        state.recentOrders = [];
        state.ordersCount = {
          pendingOrdersCount: 'N/A',
          canceledOrdersCount: 'N/A',
          deliveringOrdersCount: 'N/A',
          completeOrdersCount: 'N/A',
        };
        state.isRecentOrdersLoading = false;
      })
      .addCase(updatePayment.pending, (state) => {
        state.isUpdatingPaymentLoading = true;
        state.error = null;
      })
      .addCase(updatePayment.fulfilled, (state, action) => {
        state.isUpdatingPaymentLoading = false;
        state.orderDetail = action.payload;
      })
      .addCase(updatePayment.rejected, (state, action) => {
        state.isUpdatingPaymentLoading = false;
        state.error = action.payload;
      });
  },
});

// Export the actions to be used in components
export const { clearError } = orderSlice.actions;

// Export the reducer to be used in the store
export default orderSlice.reducer;
