import fetch from 'auth/FetchInterceptor';

const BannerService = {};

BannerService.getBanners = function () {
  return fetch({
    url: '/banner',
    method: 'get',
  });
};

BannerService.filterBanners = function (params) {
  return fetch({
    url: '/banner/filter',
    method: 'get',
    params: params,
  });
};

BannerService.addBanners = function (data) {
  return fetch({
    url: '/banner',
    method: 'post',
    data: data,
  });
};

BannerService.deleteBanners = function (id) {
  return fetch({
    url: '/banner/' + id,
    method: 'delete',
  });
};

BannerService.editBanners = function (body, id) {
  return fetch({
    url: '/banner/' + id,
    method: 'put',
    data: body,
  });
};

export default BannerService;
