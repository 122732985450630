import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import SubCategoryTypeService from 'services/SubCategoryTypeService';
import Utils from 'utils';

export const initialState = {
  loading: false,
  isSubCategoryTypesLoading: false,
  isAddingSubCategoryTypeLoading: false,
  isDeletingSubCategoryTypeLoading: false,
  isEditingSubCategoryTypeLoading: false,
  subCategoryTypeDetail: null,
  subCategoryTypes: [],
  filteredSubCategoryTypes: [],
  error: null,
};

export const getSubCategoryTypes = createAsyncThunk(
  'subCategoryTypes/list',
  async (data, { rejectWithValue }) => {
    try {
      const response = await SubCategoryTypeService.getSubCategoryTypes();
      console.log('response subCategoryTypes : ', response);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

export const getSubCategoryTypesBySubCategory = createAsyncThunk(
  'subCategoryTypes/bySubCategory/list',
  async (id, { rejectWithValue }) => {
    try {
      const response =
        await SubCategoryTypeService.getSubCategoryTypesBySubCategory(id);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

export const filterSubCategoryTypes = createAsyncThunk(
  'subCategoryTypes/filter',
  async ({ searchArray, value, fromApi, data = {} }, { rejectWithValue }) => {
    try {
      if (fromApi) {
        const response = await SubCategoryTypeService.filterSubCategoryTypes(
          data
        );
        return response;
      } else {
        const response = Utils.wildCardSearchUsingName(searchArray, value);
        return response;
      }
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

export const addSubCategoryTypes = createAsyncThunk(
  'subCategoryTypes/add',
  async (data, { rejectWithValue }) => {
    try {
      const response = await SubCategoryTypeService.addSubCategoryTypes(data);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

export const editSubCategoryTypes = createAsyncThunk(
  'subCategoryTypes/edit',
  async (data, { rejectWithValue }) => {
    try {
      console.log('data ,,, ,, ', data);
      const response = await SubCategoryTypeService.editSubCategoryTypes(
        data.body,
        data.id
      );
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

export const deleteSubCategoryType = createAsyncThunk(
  'subCategoryTypes/delete',
  async (id, { rejectWithValue }) => {
    try {
      const response = await SubCategoryTypeService.deleteSubCategoryTypes(id);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

// Slice
const subCategoryTypeSlice = createSlice({
  name: 'subCategoryTypes',
  initialState,
  reducers: {
    clearSubCategoryTypes(state) {
      state.subCategoryTypes = [];
    },
    clearError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSubCategoryTypes.pending, (state) => {
        state.isSubCategoryTypesLoading = true;
        state.error = null;
        state.subCategoryTypeDetail = null;
      })
      .addCase(getSubCategoryTypes.fulfilled, (state, action) => {
        state.isSubCategoryTypesLoading = false;
        state.subCategoryTypes = action.payload;
        state.subCategoryTypeDetail = null;
      })
      .addCase(getSubCategoryTypes.rejected, (state, action) => {
        state.isSubCategoryTypesLoading = false;
        state.error = action.payload;
        state.subCategoryTypeDetail = null;
      })
      .addCase(getSubCategoryTypesBySubCategory.pending, (state) => {
        state.isSubCategoryTypesLoading = true;
        state.error = null;
        state.subCategoryTypeDetail = null;
      })
      .addCase(getSubCategoryTypesBySubCategory.fulfilled, (state, action) => {
        state.isSubCategoryTypesLoading = false;
        state.subCategoryTypes = action.payload;
        state.subCategoryTypeDetail = null;
      })
      .addCase(getSubCategoryTypesBySubCategory.rejected, (state, action) => {
        state.isSubCategoryTypesLoading = false;
        state.error = action.payload;
        state.subCategoryTypeDetail = null;
      })
      .addCase(filterSubCategoryTypes.pending, (state) => {
        state.isSubCategoryTypesLoading = true;
        state.error = null;
        state.subCategoryTypeDetail = null;
      })
      .addCase(filterSubCategoryTypes.fulfilled, (state, action) => {
        state.isSubCategoryTypesLoading = false;
        state.filteredSubCategoryTypes = action.payload;
        state.subCategoryTypeDetail = null;
      })
      .addCase(filterSubCategoryTypes.rejected, (state, action) => {
        state.isSubCategoryTypesLoading = false;
        state.error = action.payload;
        state.filteredSubCategoryTypes = state.subCategoryTypes;
        state.subCategoryTypeDetail = null;
      })
      .addCase(addSubCategoryTypes.pending, (state) => {
        state.isAddingSubCategoryTypeLoading = true;
        state.error = null;
        state.subCategoryTypeDetail = null;
      })
      .addCase(addSubCategoryTypes.fulfilled, (state, action) => {
        state.isAddingSubCategoryTypeLoading = false;
        state.subCategoryTypeDetail = action.payload;
      })
      .addCase(addSubCategoryTypes.rejected, (state, action) => {
        state.isAddingSubCategoryTypeLoading = false;
        state.error = action.payload;
      })
      .addCase(editSubCategoryTypes.pending, (state) => {
        state.isEditingSubCategoryTypeLoading = true;
        state.error = null;
        state.subCategoryTypeDetail = null;
      })
      .addCase(editSubCategoryTypes.fulfilled, (state, action) => {
        state.isEditingSubCategoryTypeLoading = false;
        state.subCategoryTypeDetail = action.payload;
      })
      .addCase(editSubCategoryTypes.rejected, (state, action) => {
        state.isEditingSubCategoryTypeLoading = false;
        state.error = action.payload;
      })
      .addCase(deleteSubCategoryType.pending, (state) => {
        state.isDeletingSubCategoryTypeLoading = true;
        state.error = null;
      })
      .addCase(deleteSubCategoryType.fulfilled, (state, action) => {
        state.isDeletingSubCategoryTypeLoading = false;

        const deletedSubCategoryTypeId =
          action.payload &&
          action.payload.subCategoryType &&
          action.payload.subCategoryType._id;
        state.subCategoryTypes = state.subCategoryTypes.filter(
          (subCategoryType) => subCategoryType._id !== deletedSubCategoryTypeId
        );
        state.filteredSubCategoryTypes =
          state.filteredSubCategoryTypes &&
          state.filteredSubCategoryTypes.filter(
            (subCategoryType) =>
              subCategoryType._id !== deletedSubCategoryTypeId
          );
      })
      .addCase(deleteSubCategoryType.rejected, (state, action) => {
        state.isDeletingSubCategoryTypeLoading = false;
        state.error = action.payload;
      });
  },
});

// Export the actions to be used in components
export const { clearSubCategoryTypes, clearError } =
  subCategoryTypeSlice.actions;

// Export the reducer to be used in the store
export default subCategoryTypeSlice.reducer;
