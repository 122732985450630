import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import NotificationService from 'services/NotificationService';
import Utils from 'utils';

export const initialState = {
  loading: false,
  isNotificationsLoading: false,
  isAddingNotificationLoading: false,
  isDeletingNotificationLoading: false,
  isEditingNotificationLoading: false,
  notificationDetail: null,
  notifications: [],
  filteredNotifications: [],
  error: null,
};

export const getNotifications = createAsyncThunk(
  'notifications/list',
  async (data, { rejectWithValue }) => {
    try {
      const response = await NotificationService.getNotifications();
      console.log('response notifications : ', response);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

export const filterNotifications = createAsyncThunk(
  'notifications/filter',
  async ({ searchArray, value, fromApi, data = {} }, { rejectWithValue }) => {
    try {
      if (fromApi) {
        const response = await NotificationService.filterNotifications(data);
        return response;
      } else {
        const response = Utils.wildCardSearchUsingName(
          searchArray,
          value,
          'title'
        );
        return response;
      }
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

export const addNotification = createAsyncThunk(
  'notifications/add',
  async (data, { rejectWithValue }) => {
    try {
      const response = await NotificationService.addNotificationToAll(data);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

export const editNotification = createAsyncThunk(
  'notifications/edit',
  async (data, { rejectWithValue }) => {
    try {
      console.log('data ,,, ,, ', data);
      const response = await NotificationService.editNotifications(
        data.body,
        data.id
      );
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

export const deleteNotification = createAsyncThunk(
  'notifications/delete',
  async (id, { rejectWithValue }) => {
    try {
      const response = await NotificationService.deleteNotifications(id);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  }
);

// Slice
const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    clearNotifications(state) {
      state.notifications = [];
    },
    clearError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotifications.pending, (state) => {
        state.isNotificationsLoading = true;
        state.error = null;
        state.notificationDetail = null;
      })
      .addCase(getNotifications.fulfilled, (state, action) => {
        state.isNotificationsLoading = false;
        state.notifications = action.payload;
        state.notificationDetail = null;
      })
      .addCase(getNotifications.rejected, (state, action) => {
        state.isNotificationsLoading = false;
        state.error = action.payload;
        state.notificationDetail = null;
      })
      .addCase(filterNotifications.pending, (state) => {
        state.isNotificationsLoading = true;
        state.error = null;
        state.notificationDetail = null;
      })
      .addCase(filterNotifications.fulfilled, (state, action) => {
        state.isNotificationsLoading = false;
        state.filteredNotifications = action.payload;
        state.notificationDetail = null;
      })
      .addCase(filterNotifications.rejected, (state, action) => {
        state.isNotificationsLoading = false;
        state.error = action.payload;
        state.filteredNotifications = state.notifications;
        state.notificationDetail = null;
      })
      .addCase(addNotification.pending, (state) => {
        state.isAddingNotificationLoading = true;
        state.error = null;
        state.notificationDetail = null;
      })
      .addCase(addNotification.fulfilled, (state, action) => {
        state.isAddingNotificationLoading = false;
        state.notificationDetail = action.payload;
      })
      .addCase(addNotification.rejected, (state, action) => {
        state.isAddingNotificationLoading = false;
        state.error = action.payload;
      })
      .addCase(editNotification.pending, (state) => {
        state.isEditingNotificationLoading = true;
        state.error = null;
        state.notificationDetail = null;
      })
      .addCase(editNotification.fulfilled, (state, action) => {
        state.isEditingNotificationLoading = false;
        state.notificationDetail = action.payload;
      })
      .addCase(editNotification.rejected, (state, action) => {
        state.isEditingNotificationLoading = false;
        state.error = action.payload;
      })
      .addCase(deleteNotification.pending, (state) => {
        state.isDeletingNotificationLoading = true;
        state.error = null;
      })
      .addCase(deleteNotification.fulfilled, (state, action) => {
        state.isDeletingNotificationLoading = false;

        const deletedNotificationId =
          action.payload &&
          action.payload.notification &&
          action.payload.notification._id;
        state.notifications = state.notifications.filter(
          (notification) => notification._id !== deletedNotificationId
        );
        state.filteredNotifications =
          state.filteredNotifications &&
          state.filteredNotifications.filter(
            (notification) => notification._id !== deletedNotificationId
          );
      })
      .addCase(deleteNotification.rejected, (state, action) => {
        state.isDeletingNotificationLoading = false;
        state.error = action.payload;
      });
  },
});

// Export the actions to be used in components
export const { clearNotifications, clearError } = notificationSlice.actions;

// Export the reducer to be used in the store
export default notificationSlice.reducer;
